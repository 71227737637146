<template>
    <div class="indexbox">
        <img class="boximg" src="../../assets/job/1.png" alt="" srcset="">
        <jobnav name="三大业务"/>
        <div class="jobcontent">
            <div class="contentbox">
                <div class="content">
                    <div :class="active==index?'contentone1':'contentone'" v-for="(item,index) in list" :key="index" @click="changeactive(index)">{{item.title}}</div>
                </div>
            </div>
            <div class="topicon">
                <img src="../../assets/job/icon1.png" alt="">
            </div>
            <div class="bottomicon">
                <img src="../../assets/job/icon1.png" alt="">
            </div>
            <div class="mainbox">
                <div class="maintxt">
                    <div class="mainicon">
                        <img src="../../assets/job/icon2.png" alt="">
                    </div>
                    <div class="mainicon1">
                        <img src="../../assets/job/icon3.png" alt="">
                    </div>
                    <div class="maintext">
                        {{showobj.txt}}
                    </div>
                    <div class="maininfo"  @click="gopath">
                        查看详情<i class="el-icon-right" />
                    </div>
                </div>
                <div class="mainimg">
                    <img :src="showobj.img" alt="">
                </div>

            </div>
        </div>
        <jobnav name="业务分布"/>
        <div class="fenbujob">
            <img src="../../assets/job/2.png" alt="">
        </div>
    </div>
</template>
<script>
import jobnav from "../../components/jobnav.vue";
export default {
    components: {
        jobnav,
    },
    data() {
        return {
            list: [
                {
                    title: "托幼一体",
                },
                {
                    title: "产教融合",
                },
                {
                    title: "技能培训",
                },
            ],
            active: 0,
            showobj:{
                txt:'托育中心和幼儿园实体园所，从早期托育到学前教育，为0-6岁儿童提供全面的成长环境。',
                img:require('../../assets/job/3.png')
            },
            showarr:[{
                txt:'托育中心和幼儿园实体园所，从早期托育到学前教育，为0-6岁儿童提供全面的成长环境。',
                img:require('../../assets/job/3.png')
            },{
                txt:'企业与学校协同育人，共建产业学院，以实现学生与实际产业深度融合的教育目标，为行业输送高素质人才。',
                img:require('../../assets/job/4.png')
            },{
                txt:'一问职业培训学校，提供证书一站式服务，培养符合市场需求的技能型人才。',
                img:require('../../assets/job/5.png')
            }]
        };
    },
    methods:{
        gopath(){
            var arr = ["/integration","/fuse","/anissue"]
            this.$router.push(arr[this.active])
             
        },
        changeactive(index){
            this.active=index
            this.showobj = this.showarr[index]
        },
    }
};
</script>
<style lang="scss" scoped>
.indexbox {
    position: relative;
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .jobcontent {
        width: 70vw;
        background: linear-gradient(136deg, #fffcfc 0%, #f8fbff 100%);
        box-shadow: 0px 3px 12px rgba(8, 65, 144, 0.2);
        border-radius: 10px;
        padding: 26px;
        margin: 80px auto 30px;
        position: relative;
        height: 540px;
        .contentbox {
            width: 70%;
            background: #fff;
            height: 84px;
            border-radius: 40px;
            margin: 0 auto;
            position: absolute;
            top: -42px;
            left: -50%;
            right: -50%;
            .content {
                width: 96%;
                display: flex;
                height: 66px;
                background: #ffffff;
                box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.16);
                opacity: 1;
                border-radius: 33px;
                margin: 0 auto;
                justify-content: space-around;
                text-align: center;
                .contentone {
                    width: 33%;
                    height: 66px;
                    background: #ffffff;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 66px;
                    border-radius: 33px;
                }
                .contentone1 {
                    width: 33%;
                    height: 66px;
                    background: #be413f;
                    border-radius: 33px;
                    box-shadow: 0px 2px 3px rgba(190, 65, 63, 0.3);
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                    line-height: 66px;
                }
            }
        }
        .topicon {
            width: 38px;
            height: 50px;
            position: absolute;
            top: 26px;
            left: 26px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .bottomicon {
            width: 38px;
            height: 50px;
            position: absolute;
            bottom: 26px;
            left: 26px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .mainbox {
            // width: 100%;
            border-left: 1px solid rgba(190, 65, 63, 0.6);
            height: 445px;
            margin: 60px 19px;
            display: flex;
            justify-content: flex-end;
            .mainimg {
                width: 60%;
                img {
                    width: 100%;
                    height: 445px;
                    object-fit: cover;
                }
            }
            .maintxt {
                height: 445px;
                width: 40%;
                    position: relative;

                .mainicon {
                    position: absolute;
                    top: 0;
                    left: 7%;
                    width: 56px;
                    height: 55px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .mainicon1 {
                    position: absolute;
                    width: 70%;
                    height: 30%;
                    position: absolute;
                    top: 20px;
                    left: 24%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .maintext {
                    font-size: 20px;
                    color: #000000;
                    position: absolute;
                    top: 100px;
                    left: 7%;
                    width: 85%;
                }
                .maininfo {
                    position: absolute;
                    bottom: 0px;
                    right: 7%;
                    width: 190px;
                    height: 52px;
                    color: #fff;
                    line-height: 52px;
                    background: #084190;
                    border-radius: 80px 0px 0px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        font-size: 20px;
                        align-self: center;
                        margin-left: 6px;
                    }
                }
            }
        }
        
    }
    .fenbujob{
            width: 70vw;
            margin: 30px auto ;
            img{
                width: 100%;
            }
        }
}
</style>